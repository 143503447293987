.ui-dialog {
  background: $color-white;
  padding: 1rem;

  &.ui-corner-all {
    border-radius: 0;
  }

  .ui-dialog-titlebar {
    padding: 1em 0.5em;

    .ui-dialog-titlebar-icon {
      position: absolute;
      top: 1rem;
      right: 1rem;
      color: $color-black;
      transition: all 0.3s ease-in;

      &:hover {
        color: $color-primary-hover;
      }

      span {
        @extend .sh;
        @extend .sh-close;
        font-size: 0.7rem;
      }
    }

    .ui-dialog-title-inner {
      display: flex;
      align-items: center;

      &__icon {
        color: $color-neon-blue;
        font-size: 2rem;
      }

      &__text {
        @include pt-sans-15-700();
        padding: 0 1rem;
      }
    }
  }

  .ui-dialog-content {
  }

  .ui-dialog-footer {
  }

  &.ui-confirmdialog {
    .ui-dialog-titlebar {
      padding: 1.5em 0.5em 1em;
    }

    .ui-dialog-title {
      @include pt-sans-15-700();
      padding: 0 1rem;
    }
  }
}

.ui-dialog-mask {
  background: rgba(0, 0, 0, 0.8);
}
