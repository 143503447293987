.ui-scrollpanel-bar {
  width: 8px;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 20px;

  &:hover,
  &.ui-scrollpanel-grabbed {
    background: rgba(0, 0, 0, 0.4);
  }
}
