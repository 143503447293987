.ui-panel {
  body & {
    background: $color-white;
    position: relative;
    transition: box-shadow 0.3s linear;
    padding: 0 1rem;

    &::after {
      content: '';
      width: 0;
      height: 3px;
      position: absolute;
      left: 50%;
      bottom: 0;
      background: $color-neon-blue;
      transition: all 0.3s linear;
      border-radius: $borderRadius;
    }

    .ui-panel-titlebar {
      position: relative;
      border: none;
      padding: 1rem;
      line-height: 18px;
      vertical-align: middle;
      @include dosis-16-700();

      &__icon {
        color: $color-neon-blue;
        vertical-align: middle;
        margin-right: 1.5rem;
      }

      &__text {
        vertical-align: middle;
        line-height: 18px;
      }

      &__info {
        margin-left: 10px;
        line-height: 18px;
        vertical-align: middle;
        font-size: $font-size-normal;
        color: $color-gray3;
      }

      .pi {
        color: $color-gray;
      }

      &-icon {
        position: absolute;
        right: 1rem;
        top: calc(50% - 8px);
      }

      .ui-dialog-show-on-hover {
        opacity: 0.1;
        pointer-events: none;
        transition: opacity 0.3s ease-in;
        display: flex;
        align-items: center;
      }

      &:hover {
        .ui-dialog-show-on-hover {
          opacity: 1;
          pointer-events: auto;
        }
      }
    }

    .ui-panel {
      box-shadow: 2px 5px 15px $color-gray;

      &:hover {
        &::after {
          width: 0;
        }
      }
    }

    &:hover {
      box-shadow: 0px 8px 30.1px 4.9px rgba(121, 121, 148, 0.24);

      &::after {
        width: 100%;
        left: 0;
      }
    }

    &.ui-panel-clickable {
      cursor: pointer;
    }

    &.ui-corner-all {
      border-radius: $borderRadius;
    }

    .ui-panel-content {
      border: none;
      border-radius: $borderRadius;
      padding: 1rem;

      &:empty {
        padding: 0;
      }
    }
  }
}
