// sh icons
$icomoon-font-family: 'smart-html' !default;
$icomoon-font-path: '/assets/fonts' !default;
@import '../sh-icons/variables';
@import '../sh-icons/style';

@import '~primeflex/primeflex';
@import '~font-awesome/css/font-awesome.css';
@import '~primeicons/primeicons.css';
@import '~primeng/resources/primeng.css';

@import 'fonts';
@import 'utils';
@import 'helpers';

// objects
@import 'objects/input';
@import 'objects/button';
@import 'objects/tabs';
@import 'objects/tab-menu';
@import 'objects/card';
@import 'objects/panel';
@import 'objects/card';
@import 'objects/message';
@import 'objects/link';
@import 'objects/dialog';
@import 'objects/tree';
@import 'objects/dropdown';
@import 'objects/scroll-panel';
@import 'objects/progressbar';
@import 'objects/checkbox';
@import 'objects/file-upload';
@import 'objects/autocomplette';
@import 'objects/table';
@import 'objects/dragula';

// components
@import 'components/login-page';

* {
  outline: none;
}

body {
  background: $color-white-smoke;
  margin: 0;
  padding: 0;

  .ui-corner-all {
    border-radius: $borderRadius;
  }

  @include noto-sans-13();
}

.app-version {
  z-index: 1000;
  position: absolute;
  bottom: 0;
  right: 0;
  color: $color-gray;
  padding: 0.5rem 1rem;
}

.ui-overlaypanel-content {
  background: $color-white;
}
