.ui-card {
  body & {
    background: $color-white;

    &.ui-corner-all {
      border-radius: 0;
    }

    .ui-card-body {
      margin: 15px 0;
      padding: 0;
    }
  }
}
