.container {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 100vh;

  @include mq(tablet-wide) {
    grid-template-columns: 50% 50%;
  }

  background: linear-gradient(176deg, #4d4dff 25%, #5c5cfe 75%);
}

.login-container {
  section {
    display: flex;
    justify-content: center;
    align-items: center;

    p-card {
      width: 380px;

      .ui-tabmenu-nav {
        display: flex;
        justify-content: center;
        margin-bottom: 2rem;

        .ui-tabmenuitem {
          display: block;
          width: 120px;
          text-align: center;

          a {
            display: block;
            float: none;
          }
        }
      }

      .ui-card {
        padding: 70px;
      }
    }

    .login-form {
      display: flex;
      flex-direction: column;

      &--item {
        margin: 10px 0;
      }
    }
  }

  aside {
    display: none;
    align-items: center;

    img {
      width: 100%;
      height: auto;
    }

    @include mq(tablet-wide) {
      display: flex;
    }
  }
}
