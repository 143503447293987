@import '../../styles/variables';
@import '../../styles/fonts';

body {
  textarea.ui-inputtext {
    height: 60px;
    resize: vertical;
  }

  .ui-inputtext {
    background: $color-gray2;
    border-radius: $borderRadius;
    padding: 0 20px;
    border: 1px solid $color-gray;
    height: 40px;
    width: 100%;
    font-size: 11px;

    &::placeholder {
      color: $color-placeholder;
    }

    @include noto-sans-13();

    &:enabled:focus:not(.ui-state-error) {
      border: 1px solid $color-neon-blue;
    }

    &--secondary {
      background-color: $color-white;
    }
  }

  .ui-input-wrapper {
    &__inner {
      display: inline-block;
      position: relative;
      width: 100%;
    }

    .ui-inputtext {
      width: 100%;
      padding-left: 35px;
      padding-right: 35px;
      @include transition-standard(border);

      &.ui-state-error {
        border-color: $color-error;

        ~ .sh-validation.sh-error {
          opacity: 1;
          visibility: visible;
        }
      }

      &.ui-state-success {
        // border-color: $color-success;

        ~ .sh-validation.sh-tick {
          opacity: 1;
          visibility: visible;
        }
      }

      &:focus {
        ~ .sh.sh-validation {
          opacity: 0;
          visibility: hidden;
        }
      }
    }

    .sh {
      font-size: 0.8em;
      position: absolute;
      top: 14px;

      &:not(.sh-validation) {
        color: $color-neon-blue;
        left: 13px;
      }

      &.sh-validation {
        right: 13px;
        opacity: 0;
        visibility: hidden;
        @include transition-standard(opacity, visibility);
      }

      &-error {
        color: $color-error;
      }

      &-tick {
        color: $color-success;
      }
    }
  }
}
