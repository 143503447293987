.ui-tabmenu {
  body & {
    // orientation top

    .ui-tabmenu-nav {
      border: none;

      li.ui-tabmenuitem {
        margin: 0;
        background: none;
        border: none;
        border-bottom: 2px solid #ccc;
        padding: 5px 10px;
        pointer-events: auto;

        &:hover {
          background: none !important;

          a.ui-menuitem-link {
            .ui-menuitem-text {
              color: #000 !important;
              text-shadow: 1px 0.1px 0 #000;
            }
          }
        }

        &.ui-state-active {
          background: none;
          border: none;
          border-bottom: 2px solid $color-neon-blue;
          padding-top: 5px;

          &:hover {
          }

          a.ui-menuitem-link {
            .ui-menuitem-text {
              color: #000 !important;
              text-shadow: none;
            }

            @include noto-sans-13-700();
          }
        }

        a.ui-menuitem-link {
          .ui-menuitem-text {
            transition: text-shadow 0.3s ease-in;
            color: #000 !important;
          }
          @include noto-sans-13();
        }
      }
    }
  }
}
