@mixin mq($width, $type: min) {
  @if map_has_key($breakpoints, $width) {
    $width: map_get($breakpoints, $width);
    @if $type == max {
      $width: $width - 1px;
    }
    @media only screen and (#{$type}-width: $width) {
      @content;
    }
  }
}

// Simple and effective for when you need to trigger hardware acceleration for some animation, keeping everything fast, slick and flicker-free.
@mixin hardware($backface: true, $perspective: 1000) {
  @if $backface {
    backface-visibility: hidden;
  }
  perspective: $perspective;
}

// Adding Truncation to an element isn't as simple as it should be, although it is with this handy time saver, meaning when you include the mixin, you only have to specify your boundry with a max width value, happy days!
@mixin truncate($truncation-boundary) {
  max-width: $truncation-boundary;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin transition-standard($properties...) {
  $transition: ();
  @each $property in $properties {
    $transition: append($transition, $property 0.5s ease-out unquote(','));
  }
  transition: $transition;
}
