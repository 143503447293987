@charset "UTF-8";
@import url(~font-awesome/css/font-awesome.css);
@import url(~primeicons/primeicons.css);
@import url(~primeng/resources/primeng.css);
@import url("https://fonts.googleapis.com/css?family=Dosis:700|Noto+Sans:400,700|PT+Sans:400,700");
@import url("https://fonts.googleapis.com/css?family=Dosis:700|Noto+Sans:400,700|PT+Sans:400,700");
@import url("https://fonts.googleapis.com/css?family=Dosis:700|Noto+Sans:400,700|PT+Sans:400,700");
@font-face {
  font-family: "smart-html";
  src: url("/assets/fonts/smart-html.ttf?6lzwj3") format("truetype"), url("/assets/fonts/smart-html.woff?6lzwj3") format("woff"), url("/assets/fonts/smart-html.svg?6lzwj3#smart-html") format("svg");
  font-weight: normal;
  font-style: normal; }

.sh, .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon span {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "smart-html" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.sh-logo-simple:before {
  content: ""; }

.sh-layer-base:before {
  content: ""; }

.sh-add-input:before {
  content: ""; }

.sh-arrow-left:before {
  content: ""; }

.sh-arrow-right:before {
  content: ""; }

.sh-article:before {
  content: ""; }

.sh-chevron-down:before {
  content: ""; }

.sh-chevron-up:before {
  content: ""; }

.sh-close:before, .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon span:before {
  content: ""; }

.sh-download:before {
  content: ""; }

.sh-duplicate:before {
  content: ""; }

.sh-edit-01:before {
  content: ""; }

.sh-edit-02:before {
  content: ""; }

.sh-error:before {
  content: ""; }

.sh-full-view:before {
  content: ""; }

.sh-hide:before {
  content: ""; }

.sh-html:before {
  content: ""; }

.sh-image:before {
  content: ""; }

.sh-layer:before {
  content: ""; }

.sh-log-out:before {
  content: ""; }

.sh-mail:before {
  content: ""; }

.sh-menu:before {
  content: ""; }

.sh-page:before {
  content: ""; }

.sh-password:before {
  content: ""; }

.sh-plus-add:before {
  content: ""; }

.sh-preview:before {
  content: ""; }

.sh-repeat-password:before {
  content: ""; }

.sh-show:before {
  content: ""; }

.sh-tick:before {
  content: ""; }

.sh-trash:before {
  content: ""; }

.sh-upload:before {
  content: ""; }

.sh-user:before {
  content: ""; }

.sh-link:before {
  content: ""; }

.sh-settings:before {
  content: ""; }

.p-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -0.5em;
  margin-left: -0.5em;
  margin-top: -0.5em; }

.p-nogutter {
  margin-right: 0;
  margin-left: 0;
  margin-top: 0; }

.p-nogutter > .p-col,
.p-nogutter > [class*="p-col-"] {
  padding: 0; }

.p-dir-rev {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse; }

.p-dir-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column; }

.p-dir-col-rev {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse; }

.p-justify-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start; }

.p-justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end; }

.p-justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }

.p-justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.p-justify-around {
  -ms-flex-pack: distribute;
  justify-content: space-around; }

.p-justify-even {
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly; }

.p-align-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start; }

.p-align-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end; }

.p-align-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.p-align-baseline {
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline; }

.p-align-stretch {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch; }

.p-col {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  padding: 0.5em; }

.p-col-fixed {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding: 0.5em; }

.p-col-align-start {
  -ms-flex-item-align: start;
  align-self: flex-start; }

.p-col-align-end {
  -ms-flex-item-align: end;
  align-self: flex-end; }

.p-col-align-center {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center; }

.p-col-align-baseline {
  -ms-flex-item-align: baseline;
  align-self: baseline; }

.p-col-align-stretch {
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch; }

.p-col-1,
.p-col-2,
.p-col-3,
.p-col-4,
.p-col-5,
.p-col-6,
.p-col-7,
.p-col-8,
.p-col-9,
.p-col-10,
.p-col-11,
.p-col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding: 0.5em; }

.p-col-1 {
  width: 8.3333%; }

.p-col-2 {
  width: 16.6667%; }

.p-col-3 {
  width: 25%; }

.p-col-4 {
  width: 33.3333%; }

.p-col-5 {
  width: 41.6667%; }

.p-col-6 {
  width: 50%; }

.p-col-7 {
  width: 58.3333%; }

.p-col-8 {
  width: 66.6667%; }

.p-col-9 {
  width: 75%; }

.p-col-10 {
  width: 83.3333%; }

.p-col-11 {
  width: 91.6667%; }

.p-col-12 {
  width: 100%; }

.p-offset-12 {
  margin-left: 100%; }

.p-offset-11 {
  margin-left: 91.66666667%; }

.p-offset-10 {
  margin-left: 83.33333333%; }

.p-offset-9 {
  margin-left: 75%; }

.p-offset-8 {
  margin-left: 66.66666667%; }

.p-offset-7 {
  margin-left: 58.33333333%; }

.p-offset-6 {
  margin-left: 50%; }

.p-offset-5 {
  margin-left: 41.66666667%; }

.p-offset-4 {
  margin-left: 33.33333333%; }

.p-offset-3 {
  margin-left: 25%; }

.p-offset-2 {
  margin-left: 16.66666667%; }

.p-offset-1 {
  margin-left: 8.33333333%; }

.p-offset-0 {
  margin-left: 0%; }

.p-sm-1,
.p-sm-2,
.p-sm-3,
.p-sm-4,
.p-sm-5,
.p-sm-6,
.p-sm-7,
.p-sm-8,
.p-sm-9,
.p-sm-10,
.p-sm-11,
.p-sm-12,
.p-md-1,
.p-md-2,
.p-md-3,
.p-md-4,
.p-md-5,
.p-md-6,
.p-md-7,
.p-md-8,
.p-md-9,
.p-md-10,
.p-md-11,
.p-md-12,
.p-lg-1,
.p-lg-2,
.p-lg-3,
.p-lg-4,
.p-lg-5,
.p-lg-6,
.p-lg-7,
.p-lg-8,
.p-lg-9,
.p-lg-10,
.p-lg-11,
.p-lg-12,
.p-xl-1,
.p-xl-2,
.p-xl-3,
.p-xl-4,
.p-xl-5,
.p-xl-6,
.p-xl-7,
.p-xl-8,
.p-xl-9,
.p-xl-10,
.p-xl-11,
.p-xl-12 {
  padding: 0.5em; }

.p-col-nogutter {
  padding: 0; }

@media screen and (min-width: 576px) {
  .p-sm-1,
  .p-sm-2,
  .p-sm-3,
  .p-sm-4,
  .p-sm-5,
  .p-sm-6,
  .p-sm-7,
  .p-sm-8,
  .p-sm-9,
  .p-sm-10,
  .p-sm-11,
  .p-sm-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto; }
  .p-sm-1 {
    width: 8.3333%; }
  .p-sm-2 {
    width: 16.6667%; }
  .p-sm-3 {
    width: 25%; }
  .p-sm-4 {
    width: 33.3333%; }
  .p-sm-5 {
    width: 41.6667%; }
  .p-sm-6 {
    width: 50%; }
  .p-sm-7 {
    width: 58.3333%; }
  .p-sm-8 {
    width: 66.6667%; }
  .p-sm-9 {
    width: 75%; }
  .p-sm-10 {
    width: 83.3333%; }
  .p-sm-11 {
    width: 91.6667%; }
  .p-sm-12 {
    width: 100%; }
  .p-sm-offset-12 {
    margin-left: 100%; }
  .p-sm-offset-11 {
    margin-left: 91.66666667%; }
  .p-sm-offset-10 {
    margin-left: 83.33333333%; }
  .p-sm-offset-9 {
    margin-left: 75%; }
  .p-sm-offset-8 {
    margin-left: 66.66666667%; }
  .p-sm-offset-7 {
    margin-left: 58.33333333%; }
  .p-sm-offset-6 {
    margin-left: 50%; }
  .p-sm-offset-5 {
    margin-left: 41.66666667%; }
  .p-sm-offset-4 {
    margin-left: 33.33333333%; }
  .p-sm-offset-3 {
    margin-left: 25%; }
  .p-sm-offset-2 {
    margin-left: 16.66666667%; }
  .p-sm-offset-1 {
    margin-left: 8.33333333%; }
  .p-sm-offset-0 {
    margin-left: 0%; } }

@media screen and (min-width: 768px) {
  .p-md-1,
  .p-md-2,
  .p-md-3,
  .p-md-4,
  .p-md-5,
  .p-md-6,
  .p-md-7,
  .p-md-8,
  .p-md-9,
  .p-md-10,
  .p-md-11,
  .p-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto; }
  .p-md-1 {
    width: 8.3333%; }
  .p-md-2 {
    width: 16.6667%; }
  .p-md-3 {
    width: 25%; }
  .p-md-4 {
    width: 33.3333%; }
  .p-md-5 {
    width: 41.6667%; }
  .p-md-6 {
    width: 50%; }
  .p-md-7 {
    width: 58.3333%; }
  .p-md-8 {
    width: 66.6667%; }
  .p-md-9 {
    width: 75%; }
  .p-md-10 {
    width: 83.3333%; }
  .p-md-11 {
    width: 91.6667%; }
  .p-md-12 {
    width: 100%; }
  .p-md-offset-12 {
    margin-left: 100%; }
  .p-md-offset-11 {
    margin-left: 91.66666667%; }
  .p-md-offset-10 {
    margin-left: 83.33333333%; }
  .p-md-offset-9 {
    margin-left: 75%; }
  .p-md-offset-8 {
    margin-left: 66.66666667%; }
  .p-md-offset-7 {
    margin-left: 58.33333333%; }
  .p-md-offset-6 {
    margin-left: 50%; }
  .p-md-offset-5 {
    margin-left: 41.66666667%; }
  .p-md-offset-4 {
    margin-left: 33.33333333%; }
  .p-md-offset-3 {
    margin-left: 25%; }
  .p-md-offset-2 {
    margin-left: 16.66666667%; }
  .p-md-offset-1 {
    margin-left: 8.33333333%; }
  .p-md-offset-0 {
    margin-left: 0%; } }

@media screen and (min-width: 992px) {
  .p-lg-1,
  .p-lg-2,
  .p-lg-3,
  .p-lg-4,
  .p-lg-5,
  .p-lg-6,
  .p-lg-7,
  .p-lg-8,
  .p-lg-9,
  .p-lg-10,
  .p-lg-11,
  .p-lg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto; }
  .p-lg-1 {
    width: 8.3333%; }
  .p-lg-2 {
    width: 16.6667%; }
  .p-lg-3 {
    width: 25%; }
  .p-lg-4 {
    width: 33.3333%; }
  .p-lg-5 {
    width: 41.6667%; }
  .p-lg-6 {
    width: 50%; }
  .p-lg-7 {
    width: 58.3333%; }
  .p-lg-8 {
    width: 66.6667%; }
  .p-lg-9 {
    width: 75%; }
  .p-lg-10 {
    width: 83.3333%; }
  .p-lg-11 {
    width: 91.6667%; }
  .p-lg-12 {
    width: 100%; }
  .p-lg-offset-12 {
    margin-left: 100%; }
  .p-lg-offset-11 {
    margin-left: 91.66666667%; }
  .p-lg-offset-10 {
    margin-left: 83.33333333%; }
  .p-lg-offset-9 {
    margin-left: 75%; }
  .p-lg-offset-8 {
    margin-left: 66.66666667%; }
  .p-lg-offset-7 {
    margin-left: 58.33333333%; }
  .p-lg-offset-6 {
    margin-left: 50%; }
  .p-lg-offset-5 {
    margin-left: 41.66666667%; }
  .p-lg-offset-4 {
    margin-left: 33.33333333%; }
  .p-lg-offset-3 {
    margin-left: 25%; }
  .p-lg-offset-2 {
    margin-left: 16.66666667%; }
  .p-lg-offset-1 {
    margin-left: 8.33333333%; }
  .p-lg-offset-0 {
    margin-left: 0%; } }

@media screen and (min-width: 1200px) {
  .p-xl-1,
  .p-xl-2,
  .p-xl-3,
  .p-xl-4,
  .p-xl-5,
  .p-xl-6,
  .p-xl-7,
  .p-xl-8,
  .p-xl-9,
  .p-xl-10,
  .p-xl-11,
  .p-xl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto; }
  .p-xl-1 {
    width: 8.3333%; }
  .p-xl-2 {
    width: 16.6667%; }
  .p-xl-3 {
    width: 25%; }
  .p-xl-4 {
    width: 33.3333%; }
  .p-xl-5 {
    width: 41.6667%; }
  .p-xl-6 {
    width: 50%; }
  .p-xl-7 {
    width: 58.3333%; }
  .p-xl-8 {
    width: 66.6667%; }
  .p-xl-9 {
    width: 75%; }
  .p-xl-10 {
    width: 83.3333%; }
  .p-xl-11 {
    width: 91.6667%; }
  .p-xl-12 {
    width: 100%; }
  .p-xl-offset-12 {
    margin-left: 100%; }
  .p-xl-offset-11 {
    margin-left: 91.66666667%; }
  .p-xl-offset-10 {
    margin-left: 83.33333333%; }
  .p-xl-offset-9 {
    margin-left: 75%; }
  .p-xl-offset-8 {
    margin-left: 66.66666667%; }
  .p-xl-offset-7 {
    margin-left: 58.33333333%; }
  .p-xl-offset-6 {
    margin-left: 50%; }
  .p-xl-offset-5 {
    margin-left: 41.66666667%; }
  .p-xl-offset-4 {
    margin-left: 33.33333333%; }
  .p-xl-offset-3 {
    margin-left: 25%; }
  .p-xl-offset-2 {
    margin-left: 16.66666667%; }
  .p-xl-offset-1 {
    margin-left: 8.33333333%; }
  .p-xl-offset-0 {
    margin-left: 0%; } }

.u-spacing-right-10 {
  margin-right: 10px; }

.u-spacing-right-20 {
  margin-right: 20px; }

.u-inline {
  display: inline; }

.u-vertical-middle {
  vertical-align: middle; }

body textarea.ui-inputtext {
  height: 60px;
  resize: vertical; }

body .ui-inputtext {
  background: #e8e9eb;
  border-radius: 4px;
  padding: 0 20px;
  border: 1px solid #cccccc;
  height: 40px;
  width: 100%;
  font-size: 11px;
  font-family: "Noto Sans", sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #000000; }
  body .ui-inputtext::placeholder {
    color: #a0a0a0; }
  body .ui-inputtext:enabled:focus:not(.ui-state-error) {
    border: 1px solid #4d4dff; }
  body .ui-inputtext--secondary {
    background-color: #ffffff; }

body .ui-input-wrapper__inner {
  display: inline-block;
  position: relative;
  width: 100%; }

body .ui-input-wrapper .ui-inputtext {
  width: 100%;
  padding-left: 35px;
  padding-right: 35px;
  transition: border 0.5s ease-out ,; }
  body .ui-input-wrapper .ui-inputtext.ui-state-error {
    border-color: #ec1c23; }
    body .ui-input-wrapper .ui-inputtext.ui-state-error ~ .sh-validation.sh-error {
      opacity: 1;
      visibility: visible; }
  body .ui-input-wrapper .ui-inputtext.ui-state-success ~ .sh-validation.sh-tick {
    opacity: 1;
    visibility: visible; }
  body .ui-input-wrapper .ui-inputtext:focus ~ .sh.sh-validation, body .ui-input-wrapper .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon .ui-inputtext:focus ~ span.sh-validation, .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon body .ui-input-wrapper .ui-inputtext:focus ~ span.sh-validation {
    opacity: 0;
    visibility: hidden; }

body .ui-input-wrapper .sh, body .ui-input-wrapper .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon span, .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon body .ui-input-wrapper span {
  font-size: 0.8em;
  position: absolute;
  top: 14px; }
  body .ui-input-wrapper .sh:not(.sh-validation), body .ui-input-wrapper .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon span:not(.sh-validation), .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon body .ui-input-wrapper span:not(.sh-validation) {
    color: #4d4dff;
    left: 13px; }
  body .ui-input-wrapper .sh.sh-validation, body .ui-input-wrapper .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon span.sh-validation, .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon body .ui-input-wrapper span.sh-validation {
    right: 13px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease-out , visibility 0.5s ease-out ,; }
  body .ui-input-wrapper .sh-error {
    color: #ec1c23; }
  body .ui-input-wrapper .sh-tick {
    color: #8ec640; }

body .ui-button {
  background: #4d4dff;
  border-radius: 4px;
  width: 100%;
  border: 1px solid transparent;
  outline: none;
  font-family: "Noto Sans", sans-serif;
  font-size: 13px;
  font-weight: 700;
  color: #ffffff; }
  body .ui-button.ui-button-text-icon-left {
    padding-left: 20px; }
  body .ui-button .ui-button-text {
    line-height: 20px;
    padding: 9px; }
  body .ui-button.ui-state-disabled {
    background: #cdcdff; }
  body .ui-button:enabled:hover, body .ui-button:enabled:focus {
    background: #1919c8;
    border: 1px solid #1919c8; }
  body .ui-button.ui-button-danger {
    background: #f15025;
    border: 1px solid #f15025; }
    body .ui-button.ui-button-danger.ui-state-disabled {
      background: #f9b09d; }
    body .ui-button.ui-button-danger:enabled:hover, body .ui-button.ui-button-danger:enabled:focus {
      background: #d5380e;
      border: 1px solid #d5380e; }
  body .ui-button.ui-button-success {
    background: #8ec640;
    border: 1px solid #8ec640; }
    body .ui-button.ui-button-success.ui-state-disabled {
      background: #c8e3a2; }
    body .ui-button.ui-button-success:enabled:hover, body .ui-button.ui-button-success:enabled:focus {
      background: #73a330;
      border: 1px solid #73a330; }
  body .ui-button.ui-button-small {
    border-width: 2px; }
    body .ui-button.ui-button-small .ui-button-text {
      font-size: 11px;
      font-weight: bold;
      line-height: 12px;
      padding: 2px 8px; }
    body .ui-button.ui-button-small:enabled:hover, body .ui-button.ui-button-small:enabled:focus {
      border-width: 2px; }
  body .ui-button.ui-button-secondary {
    background: white;
    color: #4d4dff;
    border: 1px solid #4d4dff; }
    body .ui-button.ui-button-secondary.ui-state-disabled {
      border-color: #cdcdff;
      color: #cdcdff; }
    body .ui-button.ui-button-secondary:enabled:hover, body .ui-button.ui-button-secondary:enabled:focus {
      border-width: 1px;
      background: white;
      color: #1919c8; }
    body .ui-button.ui-button-secondary:not(.ui-button-secondary):enabled:hover, body .ui-button.ui-button-secondary:not(.ui-button-secondary):enabled:focus {
      border-color: white; }
  body .ui-button.ui-button-transparent {
    border: none;
    background: none;
    color: #4d4dff; }
    body .ui-button.ui-button-transparent .ui-button-icon-left {
      width: 100%;
      margin-left: 0;
      left: 0;
      font-size: 1.2em; }
    body .ui-button.ui-button-transparent--medium {
      font-size: 1.5em; }
    body .ui-button.ui-button-transparent--large {
      height: 30px;
      width: auto;
      font-size: 1.7em; }
    body .ui-button.ui-button-transparent:enabled:hover, body .ui-button.ui-button-transparent:enabled:focus {
      color: #1919c8;
      background: none;
      border: none; }
  body .ui-button.ui-button-round {
    height: 30px;
    width: 30px;
    border-radius: 15px; }
    body .ui-button.ui-button-round .ui-button-icon-left {
      width: 100%;
      margin-left: 0;
      left: 0; }
    body .ui-button.ui-button-round--large {
      height: 56px;
      width: 56px;
      border-radius: 28px; }
      body .ui-button.ui-button-round--large .ui-button-icon-left {
        margin-top: 0;
        height: initial;
        top: 50%;
        transform: translateY(-50%); }
        body .ui-button.ui-button-round--large .ui-button-icon-left:before {
          font-size: 3em; }
  body .ui-button.ui-button-round .sh ~ .ui-button-text, body .ui-button.ui-button-round .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon span ~ .ui-button-text, .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon body .ui-button.ui-button-round span ~ .ui-button-text, body .ui-button.ui-button-transparent .sh ~ .ui-button-text, body .ui-button.ui-button-transparent .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon span ~ .ui-button-text, .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon body .ui-button.ui-button-transparent span ~ .ui-button-text {
    padding: 0; }
  body .ui-button.ui-button-text-with-icon {
    color: #000000; }
    body .ui-button.ui-button-text-with-icon:enabled:hover, body .ui-button.ui-button-text-with-icon:enabled:focus {
      color: #303030;
      border-width: 1px; }
      body .ui-button.ui-button-text-with-icon:enabled:hover .sh, body .ui-button.ui-button-text-with-icon:enabled:hover .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon span, .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon body .ui-button.ui-button-text-with-icon:enabled:hover span, body .ui-button.ui-button-text-with-icon:enabled:focus .sh, body .ui-button.ui-button-text-with-icon:enabled:focus .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon span, .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon body .ui-button.ui-button-text-with-icon:enabled:focus span {
        color: #81b636; }
    body .ui-button.ui-button-text-with-icon .sh, body .ui-button.ui-button-text-with-icon .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon span, .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon body .ui-button.ui-button-text-with-icon span {
      color: #8ec640; }
      body .ui-button.ui-button-text-with-icon .sh ~ .ui-button-text, body .ui-button.ui-button-text-with-icon .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon span ~ .ui-button-text, .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon body .ui-button.ui-button-text-with-icon span ~ .ui-button-text {
        margin-left: 15px; }
  body .ui-button--shadow {
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.25); }
  body .ui-button--block {
    max-width: 211px; }
  body .ui-button.ui-button--black {
    color: black; }
    body .ui-button.ui-button--black:enabled:hover, body .ui-button.ui-button--black:enabled:focus {
      color: #303030; }

.ui-button-with-label {
  position: relative;
  display: flex;
  align-items: center; }
  body .ui-button-with-label .ui-button-transparent .ui-button-icon-left {
    font-size: 1.6rem; }
  .ui-button-with-label__label {
    margin-left: 10px;
    font-weight: bold;
    vertical-align: middle; }
  .ui-button-with-label--wider .ui-button-with-label__label {
    margin-left: 2rem; }

body .ui-tabview.ui-tabview-top .ui-tabview-nav {
  border: none; }
  body .ui-tabview.ui-tabview-top .ui-tabview-nav li {
    margin: 0;
    background: none;
    border: none;
    border-bottom: 2px solid #ccc;
    padding: 5px 10px; }
    body .ui-tabview.ui-tabview-top .ui-tabview-nav li:hover {
      background: none !important; }
      body .ui-tabview.ui-tabview-top .ui-tabview-nav li:hover a {
        color: #000 !important;
        text-shadow: 1px 0.1px 0 #000; }
    body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active {
      background: none;
      border: none;
      border-bottom: 2px solid #4d4dff;
      padding-top: 5px;
      font-family: "Noto Sans", sans-serif;
      font-size: 13px;
      font-weight: 700;
      color: #000000; }
      body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active a {
        color: #000 !important;
        text-shadow: none; }
    body .ui-tabview.ui-tabview-top .ui-tabview-nav li a {
      transition: text-shadow 0.3s ease-in;
      color: #000 !important;
      font-family: "Noto Sans", sans-serif;
      font-size: 13px;
      font-weight: 400;
      color: #000000; }

body .ui-tabview.ui-tabview-top .ui-tabview-panels {
  background: none;
  border: 0; }

body .ui-tabview.ui-tabview-top .ui-tabview-panel:empty {
  padding: 0; }

body .ui-tabview.ui-tabview-menu-right .ui-tabview-nav {
  display: flex;
  justify-content: flex-end; }

body .ui-tabmenu .ui-tabmenu-nav {
  border: none; }
  body .ui-tabmenu .ui-tabmenu-nav li.ui-tabmenuitem {
    margin: 0;
    background: none;
    border: none;
    border-bottom: 2px solid #ccc;
    padding: 5px 10px;
    pointer-events: auto; }
    body .ui-tabmenu .ui-tabmenu-nav li.ui-tabmenuitem:hover {
      background: none !important; }
      body .ui-tabmenu .ui-tabmenu-nav li.ui-tabmenuitem:hover a.ui-menuitem-link .ui-menuitem-text {
        color: #000 !important;
        text-shadow: 1px 0.1px 0 #000; }
    body .ui-tabmenu .ui-tabmenu-nav li.ui-tabmenuitem.ui-state-active {
      background: none;
      border: none;
      border-bottom: 2px solid #4d4dff;
      padding-top: 5px; }
      body .ui-tabmenu .ui-tabmenu-nav li.ui-tabmenuitem.ui-state-active a.ui-menuitem-link {
        font-family: "Noto Sans", sans-serif;
        font-size: 13px;
        font-weight: 700;
        color: #000000; }
        body .ui-tabmenu .ui-tabmenu-nav li.ui-tabmenuitem.ui-state-active a.ui-menuitem-link .ui-menuitem-text {
          color: #000 !important;
          text-shadow: none; }
    body .ui-tabmenu .ui-tabmenu-nav li.ui-tabmenuitem a.ui-menuitem-link {
      font-family: "Noto Sans", sans-serif;
      font-size: 13px;
      font-weight: 400;
      color: #000000; }
      body .ui-tabmenu .ui-tabmenu-nav li.ui-tabmenuitem a.ui-menuitem-link .ui-menuitem-text {
        transition: text-shadow 0.3s ease-in;
        color: #000 !important; }

body .ui-card {
  background: #ffffff; }
  body .ui-card.ui-corner-all {
    border-radius: 0; }
  body .ui-card .ui-card-body {
    margin: 15px 0;
    padding: 0; }

body .ui-panel {
  background: #ffffff;
  position: relative;
  transition: box-shadow 0.3s linear;
  padding: 0 1rem; }
  body .ui-panel::after {
    content: '';
    width: 0;
    height: 3px;
    position: absolute;
    left: 50%;
    bottom: 0;
    background: #4d4dff;
    transition: all 0.3s linear;
    border-radius: 4px; }
  body .ui-panel .ui-panel-titlebar {
    position: relative;
    border: none;
    padding: 1rem;
    line-height: 18px;
    vertical-align: middle;
    font-family: "Dosis", sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: #2c2d4f; }
    body .ui-panel .ui-panel-titlebar__icon {
      color: #4d4dff;
      vertical-align: middle;
      margin-right: 1.5rem; }
    body .ui-panel .ui-panel-titlebar__text {
      vertical-align: middle;
      line-height: 18px; }
    body .ui-panel .ui-panel-titlebar__info {
      margin-left: 10px;
      line-height: 18px;
      vertical-align: middle;
      font-size: 13px;
      color: #bbbbbb; }
    body .ui-panel .ui-panel-titlebar .pi {
      color: #cccccc; }
    body .ui-panel .ui-panel-titlebar-icon {
      position: absolute;
      right: 1rem;
      top: calc(50% - 8px); }
    body .ui-panel .ui-panel-titlebar .ui-dialog-show-on-hover {
      opacity: 0.1;
      pointer-events: none;
      transition: opacity 0.3s ease-in;
      display: flex;
      align-items: center; }
    body .ui-panel .ui-panel-titlebar:hover .ui-dialog-show-on-hover {
      opacity: 1;
      pointer-events: auto; }
  body .ui-panel .ui-panel {
    box-shadow: 2px 5px 15px #cccccc; }
    body .ui-panel .ui-panel:hover::after {
      width: 0; }
  body .ui-panel:hover {
    box-shadow: 0px 8px 30.1px 4.9px rgba(121, 121, 148, 0.24); }
    body .ui-panel:hover::after {
      width: 100%;
      left: 0; }
  body .ui-panel.ui-panel-clickable {
    cursor: pointer; }
  body .ui-panel.ui-corner-all {
    border-radius: 4px; }
  body .ui-panel .ui-panel-content {
    border: none;
    border-radius: 4px;
    padding: 1rem; }
    body .ui-panel .ui-panel-content:empty {
      padding: 0; }

body .ui-card {
  background: #ffffff; }
  body .ui-card.ui-corner-all {
    border-radius: 0; }
  body .ui-card .ui-card-body {
    margin: 15px 0;
    padding: 0; }

body .ui-message {
  border: none;
  margin: 0;
  padding: 0;
  vertical-align: text-top; }
  body .ui-message .ui-message-text {
    font-family: "Noto Sans", sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: #000000; }
  body .ui-message .ui-message-icon {
    display: none; }
  body .ui-message.ui-message-error .ui-message-text {
    color: #ec1c23; }
  body .ui-message.ui-message-success .ui-message-text {
    color: #8ec640; }
  body .ui-message.ui-message-info .ui-message-text {
    color: #4d4dff; }

a:link.link-decorated,
a:visited.link-decorated {
  font-family: "Noto Sans", sans-serif;
  font-size: 13px;
  font-weight: 700;
  color: #000000;
  text-decoration: none;
  transition: color 0.3s ease-in;
  position: relative; }
  a:link.link-decorated::before,
  a:visited.link-decorated::before {
    content: '>';
    color: #4d4dff;
    padding: 3px; }
  a:link.link-decorated::after,
  a:visited.link-decorated::after {
    content: '';
    position: absolute;
    display: block;
    transition: all 0.3s ease-in;
    width: 0;
    height: 1px;
    left: 0;
    bottom: -1px;
    background: #1919c8; }

a:hover.link-decorated {
  color: #1919c8; }
  a:hover.link-decorated::after {
    width: 100%; }

.ui-dialog {
  background: #ffffff;
  padding: 1rem; }
  .ui-dialog.ui-corner-all {
    border-radius: 0; }
  .ui-dialog .ui-dialog-titlebar {
    padding: 1em 0.5em; }
    .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon {
      position: absolute;
      top: 1rem;
      right: 1rem;
      color: #000000;
      transition: all 0.3s ease-in; }
      .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon:hover {
        color: #1919c8; }
      .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon span {
        font-size: 0.7rem; }
    .ui-dialog .ui-dialog-titlebar .ui-dialog-title-inner {
      display: flex;
      align-items: center; }
      .ui-dialog .ui-dialog-titlebar .ui-dialog-title-inner__icon {
        color: #4d4dff;
        font-size: 2rem; }
      .ui-dialog .ui-dialog-titlebar .ui-dialog-title-inner__text {
        font-family: "PT Sans", sans-serif;
        font-size: 15px;
        font-weight: 700;
        line-height: 17px;
        color: #2c2d4f;
        padding: 0 1rem; }
  .ui-dialog.ui-confirmdialog .ui-dialog-titlebar {
    padding: 1.5em 0.5em 1em; }
  .ui-dialog.ui-confirmdialog .ui-dialog-title {
    font-family: "PT Sans", sans-serif;
    font-size: 15px;
    font-weight: 700;
    line-height: 17px;
    color: #2c2d4f;
    padding: 0 1rem; }

.ui-dialog-mask {
  background: rgba(0, 0, 0, 0.8); }

.ui-tree {
  width: 100%;
  padding-left: 1em;
  position: relative;
  z-index: 0; }
  .ui-tree > * {
    z-index: 2; }
  .ui-tree .ui-tree-container {
    padding: 0 30px 0 0; }
  .ui-tree .ui-treenode {
    padding: 0; }
  .ui-tree .ui-treenode-content {
    height: 6em;
    width: 100%;
    padding: 1.8em 2em;
    font-size: 10px;
    line-height: 2.3em;
    overflow: hidden; }
    .ui-tree .ui-treenode-content:before {
      content: '';
      height: 5.9em;
      position: absolute;
      left: 0;
      right: 0;
      margin-top: -1.8em;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      pointer-events: none;
      z-index: -1;
      background-color: #5353ff; }
    .ui-tree .ui-treenode-content:not(.ui-treenode-selectable) {
      background-color: #4c50dd; }
    .ui-tree .ui-treenode-content > span {
      color: #ffffff;
      font-size: 1.3em; }
  .ui-tree .ui-treenode-leaf .ui-treenode-content {
    font-weight: bold;
    overflow: visible; }
    .ui-tree .ui-treenode-leaf .ui-treenode-content:before {
      background-color: #4e52e0; }
    .ui-tree .ui-treenode-leaf .ui-treenode-content .ui-treenode-label {
      position: relative;
      display: inline-block;
      width: 100%;
      vertical-align: top; }
      .ui-tree .ui-treenode-leaf .ui-treenode-content .ui-treenode-label .ui-treenode-label-inner {
        display: inline-block;
        max-width: var(--max-width);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
      .ui-tree .ui-treenode-leaf .ui-treenode-content .ui-treenode-label:after {
        visibility: visible;
        content: '';
        position: absolute;
        top: calc(50% - 6px);
        right: 0px;
        width: 5px;
        height: 5px;
        border-radius: 6px;
        transition: background 0.3s ease-in; }
    .ui-tree .ui-treenode-leaf .ui-treenode-content.ui-treenode-content-selected:before {
      background: #3b3bbe; }
    .ui-tree .ui-treenode-leaf .ui-treenode-content.ui-treenode-content-selected .ui-treenode-label:after {
      visibility: visible;
      background: #fff;
      border: 1px solid #fff; }
  .ui-tree .ui-treenode-leaf:hover .ui-treenode-label:after {
    visibility: visible;
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.5); }
  .ui-tree .ui-treenode-leaf .ui-treenode-label.ui-state-highlight {
    font-weight: bold; }
  .ui-tree .ui-treenode-content-selected:before {
    background: #3b3bbe; }
  .ui-tree .ui-tree-toggler {
    font-family: 'smart-html';
    height: 100%;
    position: relative;
    font-size: 0.6em; }
    .ui-tree .ui-tree-toggler:before {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-50%); }
    .ui-tree .ui-tree-toggler.pi-caret-down:before {
      content: '\e906'; }
    .ui-tree .ui-tree-toggler.pi-caret-right:before {
      content: '\e905'; }
  .ui-tree span.ui-tree-toggler {
    font-size: 0.6em; }
  .ui-tree .ui-tree-toggler,
  .ui-tree .ui-treenode-icon {
    width: 20px;
    text-align: center; }

.ui-dropdown {
  height: 40px;
  font-size: 1em;
  width: 100% !important; }
  .ui-dropdown .ui-inputtext {
    background: #ffffff;
    border: 1px solid #bbbbbb;
    border-radius: 4px;
    padding: 1em;
    color: #bbbbbb; }
  .ui-dropdown .ui-dropdown-trigger {
    width: 40px;
    color: #5050ff; }
    .ui-dropdown .ui-dropdown-trigger .ui-dropdown-trigger-icon {
      font-size: 0.65rem;
      margin-left: initial;
      transform: translateX(-50%); }
  .ui-dropdown .ui-dropdown-label {
    display: flex;
    align-items: center; }
    .ui-dropdown .ui-dropdown-label:not(.ui-placeholder) {
      font-family: "Noto Sans", sans-serif;
      font-size: 13px;
      font-weight: 700;
      color: #000000;
      color: #5050ff; }
  .ui-dropdown .ui-dropdown-panel {
    background: #ffffff; }
  .ui-dropdown.ui-dropdown-with-icon .ui-dropdown-item {
    padding: 0.6em 1em 0.6em 2.5em; }
    .ui-dropdown.ui-dropdown-with-icon .ui-dropdown-item span {
      vertical-align: middle; }
    .ui-dropdown.ui-dropdown-with-icon .ui-dropdown-item .sh, .ui-dropdown.ui-dropdown-with-icon .ui-dropdown-item .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon span, .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon .ui-dropdown.ui-dropdown-with-icon .ui-dropdown-item span {
      width: 1.5rem;
      font-size: 1.5rem;
      padding-right: 1.5rem; }
  .ui-dropdown.ui-dropdown-with-icon .ui-dropdown-label {
    padding-left: 2.5em; }
    .ui-dropdown.ui-dropdown-with-icon .ui-dropdown-label .sh, .ui-dropdown.ui-dropdown-with-icon .ui-dropdown-label .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon span, .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon .ui-dropdown.ui-dropdown-with-icon .ui-dropdown-label span {
      width: 3rem;
      font-size: 1.5rem; }

body .ui-dropdown-items {
  padding: 0;
  background: #ffffff; }

body .ui-dropdown-item {
  padding: 1em;
  border-bottom: 1px solid #cccccc; }
  body .ui-dropdown-item:focus, body .ui-dropdown-item:hover {
    background: #5050ff;
    color: #ffffff; }

.ui-scrollpanel-bar {
  width: 8px;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 20px; }
  .ui-scrollpanel-bar:hover, .ui-scrollpanel-bar.ui-scrollpanel-grabbed {
    background: rgba(0, 0, 0, 0.4); }

.main-progress-bar .ui-progressbar {
  position: fixed;
  display: none;
  width: 100vw;
  z-index: 2000;
  border-radius: 0 !important; }

.main-progress-bar--visible .ui-progressbar {
  display: block; }

.main-progress-bar .ui-progressbar-value {
  border-radius: 0 !important;
  background: #2c2d4f; }

.ui-chkbox-box {
  border: 1px solid #bbbbbb; }

.ui-fileupload {
  position: relative; }
  .ui-fileupload .ui-fileupload-content .drop-area {
    height: 100px;
    color: #cccccc;
    border: 2px dotted #cccccc; }

.ui-autocomplete {
  display: block; }

.ui-autocomplete-panel {
  background-color: white; }

.ui-table {
  position: relative; }

.ui-table table {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed; }

.ui-table .ui-table-thead > tr > th,
.ui-table .ui-table-tbody > tr > td,
.ui-table .ui-table-tfoot > tr > td {
  padding: 0.25em 0.5em; }

.ui-table .ui-sortable-column {
  cursor: pointer; }

.ui-table p-sorticon {
  vertical-align: middle; }

.ui-table-auto-layout > .ui-table-wrapper {
  overflow-x: auto; }

.ui-table-auto-layout > .ui-table-wrapper > table {
  table-layout: auto; }

/* Sections */
.ui-table-caption,
.ui-table-summary {
  padding: 0.25em 0.5em;
  text-align: center;
  font-weight: bold; }

.ui-table-caption {
  border-bottom: 0 none; }

.ui-table-summary {
  border-top: 0 none; }

/* Paginator */
.ui-table .ui-paginator-top {
  border-bottom: 0 none; }

.ui-table .ui-paginator-bottom {
  border-top: 0 none; }

/* Scrollable */
.ui-table-scrollable-wrapper {
  position: relative; }

.ui-table-scrollable-header,
.ui-table-scrollable-footer {
  overflow: hidden;
  border: 0 none; }

.ui-table-scrollable-body {
  overflow: auto;
  position: relative; }

.ui-table-virtual-table {
  position: absolute; }

/* Frozen Columns */
.ui-table-frozen-view .ui-table-scrollable-body {
  overflow: hidden; }

.ui-table-frozen-view > .ui-table-scrollable-body > table > .ui-table-tbody > tr > td:last-child {
  border-right: 0 none; }

.ui-table-unfrozen-view {
  position: absolute;
  top: 0px; }

/* Resizable */
.ui-table-resizable > .ui-table-wrapper {
  overflow-x: auto; }

.ui-table-resizable .ui-table-thead > tr > th,
.ui-table-resizable .ui-table-tfoot > tr > td,
.ui-table-resizable .ui-table-tbody > tr > td {
  overflow: hidden; }

.ui-table-resizable .ui-resizable-column {
  background-clip: padding-box;
  position: relative; }

.ui-table-resizable-fit .ui-resizable-column:last-child .ui-column-resizer {
  display: none; }

.ui-table .ui-column-resizer {
  display: block;
  position: absolute !important;
  top: 0;
  right: 0;
  margin: 0;
  width: 0.5em;
  height: 100%;
  padding: 0px;
  cursor: col-resize;
  border: 1px solid transparent; }

.ui-table .ui-column-resizer-helper {
  width: 1px;
  position: absolute;
  z-index: 10;
  display: none; }

/* Edit */
.ui-table .ui-table-tbody > tr > td.ui-editing-cell {
  padding: 0; }

.ui-table .ui-table-tbody > tr > td.ui-editing-cell p-celleditor > * {
  width: 100%; }

/* Reorder */
.ui-table-reorder-indicator-up,
.ui-table-reorder-indicator-down {
  position: absolute;
  display: none; }

/* Responsive */
.ui-table-responsive .ui-table-tbody > tr > td .ui-column-title {
  display: none; }

@media screen and (max-width: 40em) {
  .ui-table-responsive .ui-table-thead > tr > th,
  .ui-table-responsive .ui-table-tfoot > tr > td {
    display: none !important; }
  .ui-table-responsive .ui-table-tbody > tr > td {
    text-align: left;
    display: block;
    border: 0 none;
    width: 100% !important;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    float: left;
    clear: left; }
  .ui-table-responsive .ui-table-tbody > tr > td .ui-column-title {
    padding: 0.4em;
    min-width: 30%;
    display: inline-block;
    margin: -0.4em 1em -0.4em -0.4em;
    font-weight: bold; } }

/* Loader */
.ui-table-loading {
  position: absolute;
  width: 100%;
  height: 100%;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=10)';
  opacity: 0.1;
  z-index: 1; }

.ui-table-loading-content {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 2;
  margin-top: -1em;
  margin-left: -1em; }

.ui-table .ui-table-loading-icon {
  font-size: 2em; }

body .ui-table .ui-table-caption,
body .ui-table .ui-table-summary {
  background-color: #f4f4f4;
  color: #333333;
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  text-align: center; }

body .ui-table .ui-table-caption {
  border-bottom: 0 none;
  font-weight: 700; }

body .ui-table .ui-table-summary {
  border-top: 0 none;
  font-weight: 700; }

body .ui-table .ui-table-thead > tr > th {
  padding: 0.571em 0.857em;
  border: 1px solid #c8c8c8;
  font-weight: 700;
  color: #333333;
  background-color: #f4f4f4; }

body .ui-table .ui-table-tbody > tr > td {
  padding: 0.571em 0.857em; }

body .ui-table .ui-table-tfoot > tr > td {
  padding: 0.571em 0.857em;
  border: 1px solid #c8c8c8;
  font-weight: 700;
  color: #333333;
  background-color: #ffffff; }

body .ui-table .ui-sortable-column .ui-sortable-column-icon {
  color: #848484; }

body .ui-table .ui-sortable-column:not(.ui-state-highlight):hover {
  background-color: #e0e0e0;
  color: #333333; }

body .ui-table .ui-sortable-column:not(.ui-state-highlight):hover .ui-sortable-column-icon {
  color: #333333; }

body .ui-table .ui-sortable-column.ui-state-highlight {
  background-color: #007ad9;
  color: #ffffff; }

body .ui-table .ui-sortable-column.ui-state-highlight .ui-sortable-column-icon {
  color: #ffffff; }

body .ui-table .ui-editable-column input {
  font-size: 14px;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif; }

body .ui-table .ui-editable-column input:focus {
  outline: 1px solid #007ad9;
  outline-offset: 2px; }

body .ui-table .ui-table-tbody > tr {
  background: #ffffff;
  color: #333333; }

body .ui-table .ui-table-tbody > tr > td {
  background: inherit;
  border: 1px solid #c8c8c8; }

body .ui-table .ui-table-tbody > tr.ui-state-highlight {
  background-color: #007ad9;
  color: #ffffff; }

body .ui-table .ui-table-tbody > tr.ui-state-highlight a {
  color: #ffffff; }

body .ui-table .ui-table-tbody > tr.ui-contextmenu-selected {
  background-color: #007ad9;
  color: #ffffff; }

body .ui-table .ui-table-tbody > tr.ui-table-dragpoint-bottom > td {
  -webkit-box-shadow: inset 0 -2px 0 0 #007ad9;
  -moz-box-shadow: inset 0 -2px 0 0 #007ad9;
  box-shadow: inset 0 -2px 0 0 #007ad9; }

body .ui-table .ui-table-tbody > tr:nth-child(even) {
  background-color: #f9f9f9; }

body .ui-table .ui-table-tbody > tr:nth-child(even).ui-state-highlight {
  background-color: #007ad9;
  color: #ffffff; }

body .ui-table .ui-table-tbody > tr:nth-child(even).ui-state-highlight a {
  color: #ffffff; }

body .ui-table .ui-table-tbody > tr:nth-child(even).ui-contextmenu-selected {
  background-color: #007ad9;
  color: #ffffff; }

body .ui-table.ui-table-hoverable-rows .ui-table-tbody > tr.ui-selectable-row:not(.ui-state-highlight):not(.ui-contextmenu-selected):hover {
  cursor: pointer;
  background-color: #eaeaea;
  color: #333333; }

body .ui-table .ui-column-resizer-helper {
  background-color: #007ad9; }

@media screen and (max-width: 40em) {
  body .ui-table.ui-table-responsive .ui-paginator-top {
    border-bottom: 1px solid #c8c8c8; }
  body .ui-table.ui-table-responsive .ui-paginator-bottom {
    border-top: 1px solid #c8c8c8; }
  body .ui-table.ui-table-responsive .ui-table-tbody > tr > td {
    border: 0 none; } }

/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=80)';
  filter: alpha(opacity=80);
  pointer-events: none; }

/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important; }

/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important; }

/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=20)';
  filter: alpha(opacity=20); }

.container {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 100vh;
  background: linear-gradient(176deg, #4d4dff 25%, #5c5cfe 75%); }
  @media only screen and (min-width: 1024px) {
    .container {
      grid-template-columns: 50% 50%; } }

.login-container section {
  display: flex;
  justify-content: center;
  align-items: center; }
  .login-container section p-card {
    width: 380px; }
    .login-container section p-card .ui-tabmenu-nav {
      display: flex;
      justify-content: center;
      margin-bottom: 2rem; }
      .login-container section p-card .ui-tabmenu-nav .ui-tabmenuitem {
        display: block;
        width: 120px;
        text-align: center; }
        .login-container section p-card .ui-tabmenu-nav .ui-tabmenuitem a {
          display: block;
          float: none; }
    .login-container section p-card .ui-card {
      padding: 70px; }
  .login-container section .login-form {
    display: flex;
    flex-direction: column; }
    .login-container section .login-form--item {
      margin: 10px 0; }

.login-container aside {
  display: none;
  align-items: center; }
  .login-container aside img {
    width: 100%;
    height: auto; }
  @media only screen and (min-width: 1024px) {
    .login-container aside {
      display: flex; } }

* {
  outline: none; }

body {
  background: #f4f4f4;
  margin: 0;
  padding: 0;
  font-family: "Noto Sans", sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #000000; }
  body .ui-corner-all {
    border-radius: 4px; }

.app-version {
  z-index: 1000;
  position: absolute;
  bottom: 0;
  right: 0;
  color: #cccccc;
  padding: 0.5rem 1rem; }

.ui-overlaypanel-content {
  background: #ffffff; }

.header-profile {
  display: flex;
  align-items: center;
  justify-content: flex-end; }
