.ui-message {
  body & {
    border: none;
    margin: 0;
    padding: 0;
    vertical-align: text-top;

    .ui-message-text {
      @include noto-sans-13();
    }

    .ui-message-icon {
      display: none;
    }

    &.ui-message-error {
      .ui-message-text {
        color: $color-error;
      }
    }

    &.ui-message-success {
      .ui-message-text {
        color: $color-success;
      }
    }

    &.ui-message-info {
      .ui-message-text {
        color: $color-neon-blue;
      }
    }
  }
}
