.u-spacing-right-10 {
  margin-right: 10px;
}

.u-spacing-right-20 {
  margin-right: 20px;
}

.u-inline {
  display: inline;
}

.u-vertical-middle {
  vertical-align: middle;
}
