.ui-tabview {
  body & {
    // orientation top
    &.ui-tabview-top {
      .ui-tabview-nav {
        border: none;

        li {
          margin: 0;
          background: none;
          border: none;
          border-bottom: 2px solid #ccc;
          padding: 5px 10px;

          &:hover {
            background: none !important;

            a {
              color: #000 !important;
              text-shadow: 1px 0.1px 0 #000;
            }
          }

          &.ui-state-active {
            background: none;
            border: none;
            border-bottom: 2px solid $color-neon-blue;
            padding-top: 5px;

            &:hover {
            }

            a {
              color: #000 !important;
              text-shadow: none;
            }
            @include noto-sans-13-700();
          }

          a {
            transition: text-shadow 0.3s ease-in;
            color: #000 !important;
            @include noto-sans-13();
          }
        }
      }

      .ui-tabview-panels {
        background: none;
        border: 0;
      }
      .ui-tabview-panel {
        &:empty {
          padding: 0;
        }
      }
    }

    &.ui-tabview-menu-right {
      .ui-tabview-nav {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
