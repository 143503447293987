.main-progress-bar {
  .ui-progressbar {
    position: fixed;
    display: none;
    width: 100vw;
    z-index: 2000;
    border-radius: 0 !important;
  }

  &--visible .ui-progressbar {
    display: block;
  }

  .ui-progressbar-value {
    border-radius: 0 !important;
    background: $color-lucky-point;
  }
}
