@import url('https://fonts.googleapis.com/css?family=Dosis:700|Noto+Sans:400,700|PT+Sans:400,700');

@mixin pt-sans-13() {
  font-family: $pt-sans;
  font-size: $font-size-normal;
  font-weight: 400;
  line-height: 15px;
  color: $color-black;
}

@mixin pt-sans-13-700() {
  font-family: $pt-sans;
  font-size: $font-size-normal;
  font-weight: 700;
  line-height: 15px;
  color: $color-black;
}

@mixin pt-sans-15-700() {
  font-family: $pt-sans;
  font-size: 15px;
  font-weight: 700;
  line-height: 17px;
  color: $color-lucky-point;
}

@mixin noto-sans-13() {
  font-family: $noto-sans;
  font-size: $font-size-normal;
  font-weight: 400;
  color: $color-black;
}

@mixin noto-sans-13-700() {
  font-family: $noto-sans;
  font-size: $font-size-normal;
  font-weight: 700;
  color: $color-black;
}

@mixin noto-sans-13-700-white() {
  font-family: $noto-sans;
  font-size: $font-size-normal;
  font-weight: 700;
  color: $color-white;
}

@mixin dosis-16-700 {
  font-family: $dosis;
  font-size: 16px;
  font-weight: 700;
  color: $color-lucky-point;
}
