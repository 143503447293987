.ui-table {
  position: relative;
}

.ui-table table {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
}

.ui-table .ui-table-thead > tr > th,
.ui-table .ui-table-tbody > tr > td,
.ui-table .ui-table-tfoot > tr > td {
  padding: 0.25em 0.5em;
}

.ui-table .ui-sortable-column {
  cursor: pointer;
}

.ui-table p-sorticon {
  vertical-align: middle;
}

.ui-table-auto-layout > .ui-table-wrapper {
  overflow-x: auto;
}

.ui-table-auto-layout > .ui-table-wrapper > table {
  table-layout: auto;
}

/* Sections */
.ui-table-caption,
.ui-table-summary {
  padding: 0.25em 0.5em;
  text-align: center;
  font-weight: bold;
}

.ui-table-caption {
  border-bottom: 0 none;
}

.ui-table-summary {
  border-top: 0 none;
}

/* Paginator */
.ui-table .ui-paginator-top {
  border-bottom: 0 none;
}

.ui-table .ui-paginator-bottom {
  border-top: 0 none;
}

/* Scrollable */
.ui-table-scrollable-wrapper {
  position: relative;
}
.ui-table-scrollable-header,
.ui-table-scrollable-footer {
  overflow: hidden;
  border: 0 none;
}

.ui-table-scrollable-body {
  overflow: auto;
  position: relative;
}

.ui-table-virtual-table {
  position: absolute;
}

/* Frozen Columns */
.ui-table-frozen-view .ui-table-scrollable-body {
  overflow: hidden;
}

.ui-table-frozen-view > .ui-table-scrollable-body > table > .ui-table-tbody > tr > td:last-child {
  border-right: 0 none;
}

.ui-table-unfrozen-view {
  position: absolute;
  top: 0px;
}

/* Resizable */
.ui-table-resizable > .ui-table-wrapper {
  overflow-x: auto;
}

.ui-table-resizable .ui-table-thead > tr > th,
.ui-table-resizable .ui-table-tfoot > tr > td,
.ui-table-resizable .ui-table-tbody > tr > td {
  overflow: hidden;
}

.ui-table-resizable .ui-resizable-column {
  background-clip: padding-box;
  position: relative;
}

.ui-table-resizable-fit .ui-resizable-column:last-child .ui-column-resizer {
  display: none;
}

.ui-table .ui-column-resizer {
  display: block;
  position: absolute !important;
  top: 0;
  right: 0;
  margin: 0;
  width: 0.5em;
  height: 100%;
  padding: 0px;
  cursor: col-resize;
  border: 1px solid transparent;
}

.ui-table .ui-column-resizer-helper {
  width: 1px;
  position: absolute;
  z-index: 10;
  display: none;
}

/* Edit */
.ui-table .ui-table-tbody > tr > td.ui-editing-cell {
  padding: 0;
}

.ui-table .ui-table-tbody > tr > td.ui-editing-cell p-celleditor > * {
  width: 100%;
}

/* Reorder */
.ui-table-reorder-indicator-up,
.ui-table-reorder-indicator-down {
  position: absolute;
  display: none;
}

/* Responsive */
.ui-table-responsive .ui-table-tbody > tr > td .ui-column-title {
  display: none;
}

@media screen and (max-width: 40em) {
  .ui-table-responsive .ui-table-thead > tr > th,
  .ui-table-responsive .ui-table-tfoot > tr > td {
    display: none !important;
  }

  .ui-table-responsive .ui-table-tbody > tr > td {
    text-align: left;
    display: block;
    border: 0 none;
    width: 100% !important;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    float: left;
    clear: left;
  }

  .ui-table-responsive .ui-table-tbody > tr > td .ui-column-title {
    padding: 0.4em;
    min-width: 30%;
    display: inline-block;
    margin: -0.4em 1em -0.4em -0.4em;
    font-weight: bold;
  }
}

/* Loader */
.ui-table-loading {
  position: absolute;
  width: 100%;
  height: 100%;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=10)';
  opacity: 0.1;
  z-index: 1;
}

.ui-table-loading-content {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 2;
  margin-top: -1em;
  margin-left: -1em;
}

.ui-table .ui-table-loading-icon {
  font-size: 2em;
}

body .ui-table .ui-table-caption,
body .ui-table .ui-table-summary {
  background-color: #f4f4f4;
  color: #333333;
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  text-align: center;
}
body .ui-table .ui-table-caption {
  border-bottom: 0 none;
  font-weight: 700;
}
body .ui-table .ui-table-summary {
  border-top: 0 none;
  font-weight: 700;
}
body .ui-table .ui-table-thead > tr > th {
  padding: 0.571em 0.857em;
  border: 1px solid #c8c8c8;
  font-weight: 700;
  color: #333333;
  background-color: #f4f4f4;
}
body .ui-table .ui-table-tbody > tr > td {
  padding: 0.571em 0.857em;
}
body .ui-table .ui-table-tfoot > tr > td {
  padding: 0.571em 0.857em;
  border: 1px solid #c8c8c8;
  font-weight: 700;
  color: #333333;
  background-color: #ffffff;
}
body .ui-table .ui-sortable-column .ui-sortable-column-icon {
  color: #848484;
}
body .ui-table .ui-sortable-column:not(.ui-state-highlight):hover {
  background-color: #e0e0e0;
  color: #333333;
}
body .ui-table .ui-sortable-column:not(.ui-state-highlight):hover .ui-sortable-column-icon {
  color: #333333;
}
body .ui-table .ui-sortable-column.ui-state-highlight {
  background-color: #007ad9;
  color: #ffffff;
}
body .ui-table .ui-sortable-column.ui-state-highlight .ui-sortable-column-icon {
  color: #ffffff;
}
body .ui-table .ui-editable-column input {
  font-size: 14px;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
}
body .ui-table .ui-editable-column input:focus {
  outline: 1px solid #007ad9;
  outline-offset: 2px;
}
body .ui-table .ui-table-tbody > tr {
  background: #ffffff;
  color: #333333;
}
body .ui-table .ui-table-tbody > tr > td {
  background: inherit;
  border: 1px solid #c8c8c8;
}
body .ui-table .ui-table-tbody > tr.ui-state-highlight {
  background-color: #007ad9;
  color: #ffffff;
}
body .ui-table .ui-table-tbody > tr.ui-state-highlight a {
  color: #ffffff;
}
body .ui-table .ui-table-tbody > tr.ui-contextmenu-selected {
  background-color: #007ad9;
  color: #ffffff;
}
body .ui-table .ui-table-tbody > tr.ui-table-dragpoint-bottom > td {
  -webkit-box-shadow: inset 0 -2px 0 0 #007ad9;
  -moz-box-shadow: inset 0 -2px 0 0 #007ad9;
  box-shadow: inset 0 -2px 0 0 #007ad9;
}
body .ui-table .ui-table-tbody > tr:nth-child(even) {
  background-color: #f9f9f9;
}
body .ui-table .ui-table-tbody > tr:nth-child(even).ui-state-highlight {
  background-color: #007ad9;
  color: #ffffff;
}
body .ui-table .ui-table-tbody > tr:nth-child(even).ui-state-highlight a {
  color: #ffffff;
}
body .ui-table .ui-table-tbody > tr:nth-child(even).ui-contextmenu-selected {
  background-color: #007ad9;
  color: #ffffff;
}
body
  .ui-table.ui-table-hoverable-rows
  .ui-table-tbody
  > tr.ui-selectable-row:not(.ui-state-highlight):not(.ui-contextmenu-selected):hover {
  cursor: pointer;
  background-color: #eaeaea;
  color: #333333;
}
body .ui-table .ui-column-resizer-helper {
  background-color: #007ad9;
}
@media screen and (max-width: 40em) {
  body .ui-table.ui-table-responsive .ui-paginator-top {
    border-bottom: 1px solid #c8c8c8;
  }
  body .ui-table.ui-table-responsive .ui-paginator-bottom {
    border-top: 1px solid #c8c8c8;
  }
  body .ui-table.ui-table-responsive .ui-table-tbody > tr > td {
    border: 0 none;
  }
}
