.ui-fileupload {
  position: relative;

  .ui-fileupload-content {
    .drop-area {
      height: 100px;
      color: $color-gray;
      border: 2px dotted $color-gray;
    }
  }
}
