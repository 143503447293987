.ui-tree {
  width: 100%;
  padding-left: 1em;
  position: relative;

  z-index: 0;

  > * {
    z-index: 2;
  }

  .ui-tree-container {
    padding: 0 30px 0 0;
  }

  .ui-treenode {
    padding: 0;
  }

  .ui-treenode-content {
    height: 6em;
    width: 100%;
    padding: 1.8em 2em;
    font-size: 10px;
    line-height: 2.3em;
    overflow: hidden;

    &:before {
      content: '';
      height: 5.9em;
      position: absolute;
      left: 0;
      right: 0;
      margin-top: -1.8em;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      pointer-events: none;
      z-index: -1;
      background-color: #5353ff;
    }

    &:not(.ui-treenode-selectable) {
      background-color: #4c50dd;
    }

    > span {
      color: $color-white;
      font-size: 1.3em;
    }
  }

  .ui-treenode-leaf {
    .ui-treenode-content {
      font-weight: bold;
      overflow: visible;

      &:before {
        background-color: #4e52e0;
      }

      .ui-treenode-label {
        position: relative;
        display: inline-block;
        width: 100%;
        vertical-align: top;

        .ui-treenode-label-inner {
          display: inline-block;
          // used css variable to set max-width for ellipsis,
          // this variable should be set in html
          @include truncate(var(--max-width));
        }

        &:after {
          visibility: visible;
          content: '';
          position: absolute;
          top: calc(50% - 6px);
          right: 0px;
          width: 5px;
          height: 5px;
          border-radius: 6px;
          transition: background 0.3s ease-in;
        }
      }

      &.ui-treenode-content-selected {
        &:before {
          background: #3b3bbe;
        }

        .ui-treenode-label {
          &:after {
            visibility: visible;
            background: #fff;
            border: 1px solid #fff;
          }
        }
      }
    }

    &:hover {
      .ui-treenode-label {
        &:after {
          visibility: visible;
          background: transparent;
          border: 1px solid rgba(255, 255, 255, 0.5);
        }
      }
    }

    .ui-treenode-label.ui-state-highlight {
      font-weight: bold;
    }
  }

  .ui-treenode-content-selected {
    &:before {
      background: #3b3bbe;
    }
  }

  .ui-tree-toggler {
    font-family: 'smart-html';
    height: 100%;
    position: relative;
    font-size: 0.6em;

    &:before {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-50%);
    }

    &.pi-caret-down {
      &:before {
        content: '\e906';
      }
    }

    &.pi-caret-right {
      &:before {
        content: '\e905';
      }
    }
  }

  span.ui-tree-toggler {
    font-size: 0.6em;
  }

  .ui-tree-toggler,
  .ui-treenode-icon {
    width: 20px;
    text-align: center;
  }
}
