@import '../../styles/fonts';

body .ui-button {
  background: $color-neon-blue;
  border-radius: $borderRadius;
  width: 100%;
  border: 1px solid transparent;
  outline: none;

  @include noto-sans-13-700-white();

  &.ui-button-text-icon-left {
    padding-left: 20px;
  }

  .ui-button-text {
    line-height: 20px;
    padding: 9px;
  }

  &.ui-state-disabled {
    background: lighten($color-neon-blue, 25%);
  }

  &:enabled {
    &:hover,
    &:focus {
      background: $color-primary-hover;
      border: 1px solid $color-primary-hover;
    }
  }

  &.ui-button-danger {
    background: $color-flame;
    border: 1px solid $color-flame;

    &.ui-state-disabled {
      background: lighten($color-flame, 25%);
    }

    &:enabled {
      &:hover,
      &:focus {
        background: darken($color-flame, 10%);
        border: 1px solid darken($color-flame, 10%);
      }
    }
  }

  &.ui-button-success {
    background: $color-success;
    border: 1px solid $color-success;

    &.ui-state-disabled {
      background: lighten($color-success, 25%);
    }

    &:enabled {
      &:hover,
      &:focus {
        background: darken($color-success, 10%);
        border: 1px solid darken($color-success, 10%);
      }
    }
  }

  &.ui-button-small {
    border-width: 2px;

    .ui-button-text {
      font-size: 11px;
      font-weight: bold;
      line-height: 12px;
      padding: 2px 8px;
    }

    &:enabled:hover,
    &:enabled:focus {
      border-width: 2px;
    }
  }

  &.ui-button-secondary {
    background: white;
    color: $color-neon-blue;
    border: 1px solid $color-neon-blue;

    &.ui-state-disabled {
      border-color: lighten($color-neon-blue, 25%);
      color: lighten($color-neon-blue, 25%);
    }

    &:enabled {
      &:hover,
      &:focus {
        border-width: 1px;
        background: white;
        color: $color-primary-hover;
      }
    }

    &:not(.ui-button-secondary):enabled {
      &:hover,
      &:focus {
        border-color: white;
      }
    }
  }

  &.ui-button-transparent {
    border: none;
    background: none;
    color: $color-neon-blue;

    .ui-button-icon-left {
      width: 100%;
      margin-left: 0;
      left: 0;
      font-size: 1.2em;
    }

    &--medium {
      font-size: 1.5em;
    }

    &--large {
      height: 30px;
      width: auto;
      font-size: 1.7em;
    }

    &:enabled {
      &:hover,
      &:focus {
        color: $color-primary-hover;
        background: none;
        border: none;
      }
    }
  }

  &.ui-button-round {
    height: 30px;
    width: 30px;
    border-radius: 15px;

    .ui-button-icon-left {
      width: 100%;
      margin-left: 0;
      left: 0;
    }

    &--large {
      height: 56px;
      width: 56px;
      border-radius: 28px;

      .ui-button-icon-left {
        margin-top: 0;
        height: initial;
        top: 50%;
        transform: translateY(-50%);

        &:before {
          font-size: 3em;
        }
      }
    }
  }

  &.ui-button-round,
  &.ui-button-transparent {
    .sh {
      ~ .ui-button-text {
        padding: 0;
      }
    }
  }

  &.ui-button-text-with-icon {
    color: $color-black;

    &:enabled {
      &:hover,
      &:focus {
        color: $color-dark-gray;
        border-width: 1px;

        .sh {
          color: darken($color-success, 5%);
        }
      }
    }

    .sh {
      color: $color-success;
      ~ .ui-button-text {
        margin-left: 15px;
      }
    }
  }

  &--shadow {
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.25);
  }

  &--block {
    max-width: 211px;
  }

  &.ui-button--black {
    color: black;

    &:enabled {
      &:hover,
      &:focus {
        color: $color-dark-gray;
      }
    }
  }
}

.ui-button-with-label {
  position: relative;
  display: flex;
  align-items: center;

  .ui-button-transparent .ui-button-icon-left {
    body & {
      font-size: 1.6rem;
    }
  }

  &__label {
    margin-left: 10px;
    font-weight: bold;
    vertical-align: middle;
  }

  &--wider {
    .ui-button-with-label__label {
      margin-left: 2rem;
    }
  }
}
