@import 'variables';

@font-face {
  font-family: '#{$icomoon-font-family}';
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?6lzwj3') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?6lzwj3') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?6lzwj3##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
}

.sh {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sh-logo-simple {
  &:before {
    content: $sh-logo-simple;
  }
}
.sh-layer-base {
  &:before {
    content: $sh-layer-base;
  }
}
.sh-add-input {
  &:before {
    content: $sh-add-input;
  }
}
.sh-arrow-left {
  &:before {
    content: $sh-arrow-left;
  }
}
.sh-arrow-right {
  &:before {
    content: $sh-arrow-right;
  }
}
.sh-article {
  &:before {
    content: $sh-article;
  }
}
.sh-chevron-down {
  &:before {
    content: $sh-chevron-down;
  }
}
.sh-chevron-up {
  &:before {
    content: $sh-chevron-up;
  }
}
.sh-close {
  &:before {
    content: $sh-close;
  }
}
.sh-download {
  &:before {
    content: $sh-download;
  }
}
.sh-duplicate {
  &:before {
    content: $sh-duplicate;
  }
}
.sh-edit-01 {
  &:before {
    content: $sh-edit-01;
  }
}
.sh-edit-02 {
  &:before {
    content: $sh-edit-02;
  }
}
.sh-error {
  &:before {
    content: $sh-error;
  }
}
.sh-full-view {
  &:before {
    content: $sh-full-view;
  }
}
.sh-hide {
  &:before {
    content: $sh-hide;
  }
}
.sh-html {
  &:before {
    content: $sh-html;
  }
}
.sh-image {
  &:before {
    content: $sh-image;
  }
}
.sh-layer {
  &:before {
    content: $sh-layer;
  }
}
.sh-log-out {
  &:before {
    content: $sh-log-out;
  }
}
.sh-mail {
  &:before {
    content: $sh-mail;
  }
}
.sh-menu {
  &:before {
    content: $sh-menu;
  }
}
.sh-page {
  &:before {
    content: $sh-page;
  }
}
.sh-password {
  &:before {
    content: $sh-password;
  }
}
.sh-plus-add {
  &:before {
    content: $sh-plus-add;
  }
}
.sh-preview {
  &:before {
    content: $sh-preview;
  }
}
.sh-repeat-password {
  &:before {
    content: $sh-repeat-password;
  }
}
.sh-show {
  &:before {
    content: $sh-show;
  }
}
.sh-tick {
  &:before {
    content: $sh-tick;
  }
}
.sh-trash {
  &:before {
    content: $sh-trash;
  }
}
.sh-upload {
  &:before {
    content: $sh-upload;
  }
}
.sh-user {
  &:before {
    content: $sh-user;
  }
}
.sh-link {
  &:before {
    content: $sh-link;
  }
}
.sh-settings {
  &:before {
    content: $sh-settings;
  }
}
