.ui-dropdown {
  height: 40px;
  font-size: 1em;
  width: 100% !important; // ?

  .ui-inputtext {
    background: $color-white;
    border: 1px solid $color-gray3;
    border-radius: 4px;
    padding: 1em;
    color: $color-gray3;
  }

  .ui-dropdown-trigger {
    width: 40px;
    color: #5050ff;

    .ui-dropdown-trigger-icon {
      font-size: 0.65rem;
      margin-left: initial;
      transform: translateX(-50%);
    }
  }

  .ui-dropdown-label {
    display: flex;
    align-items: center;

    &:not(.ui-placeholder) {
      @include noto-sans-13-700();
      color: #5050ff;
    }
  }

  .ui-dropdown-panel {
    background: $color-white;
  }

  &.ui-dropdown-with-icon {
    .ui-dropdown-item {
      padding: 0.6em 1em 0.6em 2.5em;

      span {
        vertical-align: middle;
      }

      .sh {
        width: 1.5rem;
        font-size: 1.5rem;
        padding-right: 1.5rem;
      }
    }

    .ui-dropdown-label {
      padding-left: 2.5em;

      .sh {
        width: 3rem;
        font-size: 1.5rem;
      }
    }
  }
}

body {
  .ui-dropdown-items {
    padding: 0;
    background: $color-white;
  }

  .ui-dropdown-item {
    padding: 1em;
    border-bottom: 1px solid $color-gray;

    &:focus,
    &:hover {
      background: #5050ff;
      color: $color-white;
    }
  }
}
