a:link,
a:visited {
  &.link-decorated {
    @include noto-sans-13-700();
    text-decoration: none;
    transition: color 0.3s ease-in;
    position: relative;

    &::before {
      content: '>';
      color: $color-neon-blue;
      padding: 3px;
    }

    &::after {
      content: '';
      position: absolute;
      display: block;
      transition: all 0.3s ease-in;
      width: 0;
      height: 1px;
      left: 0;
      bottom: -1px;
      background: $color-primary-hover;
    }
  }
}

a:hover {
  &.link-decorated {
    color: $color-primary-hover;
    &::after {
      width: 100%;
    }
  }
}
